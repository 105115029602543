
import { defineComponent } from 'vue';
import MPageHeader from '@/components/pageHeader/PageHeader.vue';

export default defineComponent({
  name: 'Home',
  components: {
    'm-page-header': MPageHeader,
  },
  setup() {
    const imgMap: any = {};
    const requireContext = require.context('../statics/img', false, /(png)$/);
    requireContext.keys().forEach(path => {
      const filename = (path.split('/').pop() as string).split('.').shift() as string;
      imgMap[filename] = requireContext(path);
    });

    return {
      steps: [
        '1. 首先安装广告看板Chrome浏览器插件，将店铺与Facebook广告平台建立连接;点击下载按钮，下载Chrome数据辅助插件',
        '2. 解压已下载的插件文件，解压后的文件不可修改、移动或删除，否则将导致Chrome插件不可用',
        '3. 依次浏览器右上角「┇」→「设置」→「扩展程序」，打开Chrome浏览器插件管理界面',
        '4. 开启开发者模式',
        '5. 点击“加载已解压的扩展程序”然后选择文件确定后点击“选择文件夹”',
        '6. 安装完成后会在插件列表显示，点击扩展程序选择图钉图标可以固定在浏览器窗口',
        '7. 运行插件，点击店铺管理(可添加多个店铺)',
        '8. 点击添加店铺',
        '9. 需要填写店铺名称，将应用界面内的网关地址、密钥依次复制并填入',
        '10. 回到应用界面，将本栏目下"网关地址"、"秘钥"复制在一旁备用',
        '11. 接下来在Facebook中获取对应的广告ID和广告时区。登陆Facebook，进入广告管理工具https://www.facebook.com/adsmanager/manage',
        '12. 获取广告账户编号，可以从网址链接中的act=广告账户编号或在账号列表中直接获取，请将此参数复制在一旁备用',
        '13. 获取广告账户时区，点击右上角的时间筛选，列表中就能轻松地查看到该账户使用的时区，请将此参数复制在一旁备用',
        '14. 再次打开工具插件，依次点击「店铺管理」→「添加店铺」，填写店铺名称，并将之前记录的信息复制至对应输入框中',
        '15. 回到店铺管理列表，可发现店铺已添加成功',
        '16. 在Facebook广告管理后台页面打开后，在右上角插件列表找到广告看板工具插件，并开启“广告看板工具”开关',
        '17. 接下来我们去创建广告，添加UTM参数，实现订单广告数据双向匹配。先回到应用界面，复制UTM参数',
        '18. 创建广告时，将复制好的UTM添加要投放的网站链接结尾处组成新的投放链接。注：对于之前已经创建好的存量广告，也可以在网址后粘贴UTM参数后进行广告跟踪，但之前已产生的订单数据不做统计',
        '19. 在Facebook广告管理工具，进入到广告系列中，点击数据报表右上角的栏：自定义-定制栏中',
        '20. 在定制栏中，勾选以下6个指标并请按照下图调整好显示的顺序：广告系列编号>广告组编号>广告编号>花费金额>成效>广告花费回报(ROAS)-购物',
        '21. 在广告数据展示页面上，可以看到Facebook的蓝色图标，其内所展示的数值就是真实的广告成效数据。注：若无效果，则请检查插件的店铺是否已配置正确、状态是否已开启，检查完成后刷新页面',
        '22. 至此，您已完成广告看板工具相关的所有配置步骤。插件将根据店铺订单来源所附带的UTM参数将数据匹配给Facebook对应的广告系列，如此就能准确地将订单与广告一一对应匹配了',
      ],
      imgMap,
    };
  },
});
